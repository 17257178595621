import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes, USER_TYPE_ORGANISATIONS } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  CustomExtendedDataField,
  H5,
} from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';
import PhoneInput from 'react-phone-input-2';

import css from './SignupForm.module.css';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => {
  if (typeof window === 'undefined') return null;
  require('react-phone-input-2/lib/style.css');
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      initialValues={{
        userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes),
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
          preselectedUserType,
          userTypes,
          userFields,
          values,
          form,
        } = formRenderProps;

        const { userType } = values || {};

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // Custom user fields. Since user types are not supported here,
        // only fields with no user type id limitation are selected.
        const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

        const noUserTypes = !userType && !(userTypes?.length > 0);
        const userTypeConfig = userTypes.find(config => config.userType === userType);
        const showDefaultUserFields = userType || noUserTypes;
        const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;
        const signupFormTagline = 'Expert Care for Your Well-Being';
        const showOrgNameField = values.userRole === USER_TYPE_ORGANISATIONS;
        const disableOtherFields = !values.userRole;

        const [phoneNumber, setPhoneNumber] = useState({
          value: '',
          countryCode: '',
          country: '',
          number: '',
        });
        const handleOnChange = (value, country) => {
          const { name = '', dialCode = '', countryCode = '', format } = country;
          form.change('phoneNumber', '+' + value);
          setPhoneNumber({
            value: value,
            countryCode: countryCode,
            countryName: name,
            dialCode: dialCode,
          });

          let filterLength = 0;
          for (let i = 0; i < format.length; i++) {
            if (format[i] == '.') {
              filterLength += 1;
            }
          }
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.formFld}>
              <H5 className={css.subtitle}>{signupFormTagline}</H5>
              <div className={css.radioWrapper}>
                <FieldSelectUserType
                  name="userRole"
                  userTypes={userTypes}
                  hasExistingUserType={!!preselectedUserType}
                  intl={intl}
                />
              </div>
            </div>
            {/* Org name only visible if role is organization*/}
            {showOrgNameField ? (
              <FieldTextInput
                className={css.organizationName}
                type="text"
                id={formId ? `${formId}.organizationName` : 'organizationName'}
                name="organizationName"
                autoComplete="given-name"
                label={intl.formatMessage({
                  id: 'SignupForm.organizationNameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.organizationNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.organizationNameRequired',
                  })
                )}
                disabled={disableOtherFields}
              />
            ) : null}

            <div className={css.defaultUserFields}>
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              
              <div className={css.phoneWrapper}>
                {/* <div className={css.phoneLabel}>Phone number</div> */}
                <PhoneInput
                  className={css.phoneBox}
                  name="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  country={'gb'}
                  value={phoneNumber.value}
                  onChange={handleOnChange}
                />
              </div>

              <UserFieldDisplayName
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>

            {showCustomUserFields ? (
              <div className={css.customFields}>
                {userFieldProps.map(fieldProps => (
                  <CustomExtendedDataField {...fieldProps} formId={formId} />
                ))}
              </div>
            ) : null}

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp1" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
