import React from 'react';
import { bool, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';

import { FieldRadioButton, FieldSelect } from '../../components';

import css from './AuthenticationPage.module.css';

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

/**
 * Return React Final Form Field that allows selecting user type.
 *
 * @param {*} props containing name, userTypes, hasExistingUserType, intl
 * @returns React Final Form Field component to select user type
 */
const FieldSelectUserType = props => {
  const { rootClassName, className, name, userTypes, hasExistingUserType, intl } = props;
  const hasMultipleUserTypes = userTypes?.length > 1;
  const classes = classNames(rootClassName || css.userTypeSelect, className);

  return hasMultipleUserTypes && !hasExistingUserType ? (
    <>
      {userTypes.map((st, i) => {
           const type = st.userType;
        return(
          <div  key={st.label + '_' + i}>
          <FieldRadioButton
            id={type}
            name={name}
            value={type}
            label={st.label}
          />
        </div>
        )
      }
      )}
    </>
  ) : (
    <>
      <FieldHidden name={name} />
    </>
  );
};

FieldSelectUserType.defaultProps = {
  rootClassName: null,
  className: null,
  hasExistingUserType: false,
};

FieldSelectUserType.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  userTypes: propTypes.userTypes.isRequired,
  hasExistingUserType: bool,
  intl: intlShape.isRequired,
};

export default FieldSelectUserType;
